.low-progress-assigned {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  max-height: 385px;
  width: 100%;
}

.low-progress-assigned .top-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: #e8eaec;
  padding: 6px 0;
  margin: 10px 0 15px;
  line-height: 25px;
  width: 350px;
  border-radius: 10px;
}

.low-progress-assigned .top-toggle div {
  padding: 3px 30px;
  cursor: pointer;
}

.low-progress-assigned .top-toggle .active {
  background: #ffffff;
  border-radius: 5px;
  font-weight: 500;
}

.low-progress-assigned .graph-headers {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  font-size: 11px;
  margin: 40px 0 20px;
}

.low-progress-assigned .graph-headers .left-header {
  font-weight: 500;
  width: 20%;
  text-align: right;
}

.low-progress-assigned .graph-headers .center-header {
  display: flex;
  justify-content: center;
  width: 60%;
  min-width: 500px;
  padding: 0 10px;
}

.low-progress-assigned .graph-headers .center-header .legend-item {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.low-progress-assigned .graph-headers .center-header .legend-item .color-swatch {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  margin: 3px 8px;
}

.low-progress-assigned .graph-headers .right-header {
  font-weight: 500;
  width: 20%;
  padding-right: 15px;
}

.low-progress-assigned .low-progress-graph {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  margin-right: -15px;
  width: 100%;
  height: calc(100% - 200px);
  min-height: 250px;
  font-size: 12px;
}

.low-progress-assigned .low-progress-graph .low-progress-graph-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.low-progress-assigned .low-progress-graph .item-name {
  width: 20%;
  text-align: right;
}

.low-progress-assigned .low-progress-graph .horizontal-bar-graph {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 6px 40px 6px 30px;
  border-radius: 10px;
  cursor: pointer;
}

.low-progress-assigned .low-progress-graph .horizontal-bar-graph .complete-bar {
  background: #4eb3d4;
  width: 50%;
  height: 24px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.low-progress-assigned .low-progress-graph .horizontal-bar-graph .incomplete-bar {
  background: #3d68d3;
  width: 50%;
  height: 24px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.low-progress-assigned .low-progress-graph .item-score {
  width: 18%;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline-title {
  width: 20%;
  text-align: right;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 0;
  margin: 0 40px 0 30px;
  padding: 2px;
  justify-content: center;
  align-items: flex-start;
  overflow-y: hidden;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline::-webkit-scrollbar {
  width: 1px;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline .line-top {
  height: 5px;
  width: 100%;
  border-left: 1px solid;
  border-right: 1px solid;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline .line-bottom {
  border-top: 1px solid;
  height: 5px;
  width: 100%;
  border-left: 1px solid;
  border-right: 1px solid;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline .dates {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 10px;
  width: 100%;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline .dates .timeline-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 35px;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline .dates .dot {
  height: 10px;
  width: 10px;
  border-radius: 30px;
  background: #4eb3d4;
  margin: -10px 0 5px;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline.wide {
  width: calc(100% - 440px);
  align-items: flex-start;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline.wide .line-top,
.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline.wide .line-bottom,
.low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline.wide .dates {
  width: 100vw;
  margin: 0 10px;
}

.low-progress-assigned .low-progress-graph .low-progress-timeline-row .end-filler {
  width: 18%;
}

.low-progress-assigned .graph-footers {
  display: flex;
  flex-direction: column;
  width: 65%;
  font-size: 11px;
  font-weight: 500;
}

.low-progress-assigned .graph-footers .graph-ticks {
  display: flex;
  flex-direction: row;
  margin: 20px -10px 8px 35px;
  padding-right: 15px;
}

.low-progress-assigned .graph-footers .graph-ticks .graph-tick {
  width: 10%;
  min-width: 30px;
  text-align: center;
}

.low-progress-assigned .graph-footers .axis-label {
  display: flex;
  justify-content: center;
} 