.Toastify__toast {
  width: fit-content;
  margin-left: auto;
  box-shadow: 0 0 20px 0 var(--black-two33);
  border: solid 3px;
  border-radius: 10px;
  padding: 15px 15px;
  min-height: 78px;
  background-color: var(--white);
}

.Toastify__toast-container {
  width: auto;
  min-width: 450px;
  z-index: 2147483641;
}

.Toastify__toast--error {
  border-color: var(--danger);
}

.Toastify__toast--success {
  border-color: var(--success);
}

.Toastify__toast--info {
  border-color: var(--info);
}

.Toastify__toast--warning {
  border-color: var(--warning);
}

.Toastify__toast-body {
  margin-right: 10px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  display: flex;
  align-items: center;
}

.Toastify__toast-content {
  display: flex;
}

.Toastify__toast-content > img {
  margin: auto 0;
}

.Toastify__toast-text-wrapper {
  color: var(--black-two);
  margin-left: 10px;
  border-left: 1px solid var(--ice-blue);
  padding: 0 10px;
  font-size: 16px;
  line-height: 1.34;
}

.Toastify__toast-icon {
  margin-right: 10px;
}

.Toastify__toast-text {
  flex-grow: 1;
  padding-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Toastify__close-button {
  margin: -5px;
}

.Toastify__close-button svg {
  height: 20px;
  width: 20px;
}

.Toastify__close-button svg path {
  fill: var(--black-two);
} 