:root {
  --mobile-width: 1000px;

  --large-font: 30px;
  --medium-font: 20px;
  --normal-font-size: 14px;
  --small-font: 12px;

  --normal-font-weight: 300;
  --bold-font: 400;
  --bolder-font: 600;

  --white: #ffffff;
  --white-back: #f5f5f5;
  --black: #151515;
  --shadow-black: rgba(0, 0, 0, 0.2);
  --bg-grey: #f4f7f9;
  --grey: #96a6b3;
  --grey1: #474f55;
  --grey-border: #dee2e6;
  --grey-hover: rgba(0, 0, 0, 0.05);

  --navy: rgb(9, 16, 52);
  --brown: #533f03;
  --yellow: #f4a93f;
  --dark-blue: #27265f;
  --light-blue: #6ebddb;

  --blue: #4eb3d4;
  --salmon: #f16153;
  --slate: #212126;
  --clouds: #e1e3e9;
  --btn-blue: #dfeef2;
  --sidebar-blue: #e8f1f3;

  --primary: #00acc1;
  --warning: #ff9800;
  --danger: #f44336;
  --success: #4caf50;
  --info: #00acc1;
  --rose: #e91e63;
  --background-grey: #ececec;

  --grid-gutter-width: 24px;

  --btn-border-radius: 0.15rem;
  --spacer: 1.5rem;

  --icon-btn-font-size: 1.25rem;
} 