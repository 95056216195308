.graph-placeholder {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6px);
}

.graph-placeholder.loaded {
  transition: all 0.2s;
  backdrop-filter: none;
  background-color: rgba(255, 255, 255, 0);
}
