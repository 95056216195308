.security-score-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.security-score-footer .level-container {
  font-weight: 500;
}

.security-score-footer .level-container .fad {
  margin-right: 5px;
}

.security-score-footer .change-container {
  font-size: 13px;
  line-height: 22px;
}

.security-score-footer .change-container .far {
  margin-left: 5px;
}
