.assigned-completion-rate-graph {
  font-size: 60px;
  font-weight: 500;
  text-align: center;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
