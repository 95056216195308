.bottom-rank-graph {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.bottom-rank-graph .bottom-rank-graph-left-nav {
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 125px;
  height: 281px;
  font-size: 12px;
  text-align: right;
  text-transform: capitalize;
  padding: 20px 0 0 20px;
  background: #edf7fb;
  border-right: 2px solid #4eb3d4;
  align-items: flex-end;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin-left: -20px;
  overflow-x: hidden;
}

.bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-scroll {
  height: 281px;
  margin-right: -15px;
  padding-right: 45px;
  overflow-x: hidden;
}

.bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item {
  margin: 0 -40px 5px 0;
  padding: 10px 30px 10px 7px;
  border-radius: 13px;
  width: 180px;
}

.bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item .fal {
  display: none;
  float: right;
  font-size: 10px;
  margin-right: -20px;
  line-height: 14px;
  font-weight: 600;
}

.bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item.active,
.bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item:hover {
  background: white;
  box-shadow: 3px 0 10px 0 #eaeaea;
  font-weight: 500;
  cursor: pointer;
}

.bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item.active .fal,
.bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item:hover .fal {
  display: block;
}

.bottom-rank-graph .bottom-rank-graph-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 80%;
  height: 280px;
  min-width: 224px;
  padding: 20px 10px 20px 40px;
}

.bottom-rank-graph .bottom-rank-graph-body .no-data {
  margin: 100px auto;
}

.bottom-rank-graph .bottom-rank-graph-body .headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 12px;
}

.bottom-rank-graph .bottom-rank-graph-body .body {
  overflow-x: hidden;
}

.bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 9px 20px;
  border-radius: 10px;
  margin: 3px -20px;
}

.bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row:nth-child(even) {
  background: #f4f7f9;
}

.bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row .rank-score {
  display: inline-flex;
  min-width: 30px;
  text-align: center;
  justify-content: center;
}
