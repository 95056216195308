.security-score-by-category-labels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 202px;
  width: 300px;
  line-height: 21px;
  padding: 26px 0;
  text-align: right;
}

#root > div > div > div > div > svg > g:nth-child(2) > line {
  display: none;
}

#root > div > div > div > div > svg > g:nth-child(3) > line {
  display: none;
}
