:root {
  --black: #151515;
  --slate: #212126;
  --white: #ffffff;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.DateInput_input {
  width: 83px;
}

.DateInput_input__focused {
  outline: 0;
  background: #dfeef2;
}

.loading-container {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ce-reporting-dashboard {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  font: 14px 'Gotham', sans-serif;
  line-height: normal;
  font-weight: 400;
  color: var(--black);
  overflow-x: hidden;
}

#ce-reporting-dashboard .__react_component_tooltip.place-top {
  margin-top: -4px;
  background: var(--slate);
  border: 1px solid var(--slate);
  opacity: 1;
  z-index: 9999999;
}

#ce-reporting-dashboard.panel-open {
  overflow: hidden;
}

#ce-reporting-dashboard .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--black);
  opacity: 0.6;
  filter: blur(2px);
}

#ce-reporting-dashboard .modal-backdrop.light {
  background-color: var(--white);
  opacity: 0.6;
}

#ce-reporting-dashboard .modal-backdrop.clear {
  background-color: transparent;
}

#ce-reporting-dashboard #page-container {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}

#ce-reporting-dashboard .modal-title {
  font-size: 26px;
  font-weight: 600;
}

button {
  outline: none !important;
}

.ls-button:focus {
  outline: none !important;
}

.modal-content {
  -webkit-animation: fadein 1.3s;
  -moz-animation: fadein 1.3s;
  -ms-animation: fadein 1.3s;
  -o-animation: fadein 1.3s;
  animation: fadein 1.3s;
}

@media screen and (max-width: 1200px) {
  #ce-reporting-dashboard {
  }
}

#filter-container .bp3-control:not(.bp3-align-right) {
  padding-left: 26px;
  padding-right: 0;
}

#filter-container .ReportsFilter__inner label {
  padding: 0;
}

#filter-container .ReportsFilter__AddFilterDropdown {
  top: 96px;
} 