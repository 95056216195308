#reporting-container {
  position: relative;
  min-width: 850px;
  padding: 0 30px;
}

#reporting-container #filter-container .ls-card {
  margin: 24px 0 0;
  padding: 20px;
  width: calc(100% - 40px);
}

#reporting-container .page-title {
  padding: 30px 0 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

#reporting-container .page-title h2 {
  display: inline-block;
  font-size: 2.625rem;
  font-weight: normal;
}

#reporting-container .page-title .details-container {
  display: flex;
  float: right;
  font-size: 12px;
  line-height: 36px;
}

#reporting-container .page-title .details-container #download-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  height: 32px;
  width: 140px;
  font-size: 12px;
  line-height: 2.67;
  border-radius: 5px;
  background: var(--btn-blue);
  cursor: pointer;
}

#reporting-container .page-title .details-container #download-buttons:hover {
  background: #d5e7ec;
}

#reporting-container .page-title .details-container #download-buttons i {
  height: 10px;
}

#reporting-container .page-title .details-container #download-buttons #download-button {
  width: 75%;
}

#reporting-container .page-title .details-container #download-buttons .download-button--inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#reporting-container .page-title .details-container #download-buttons .download-button--inner-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  padding: 0 6px;
  border-radius: 5px;
  background-color: #f4f7f9;
}

#reporting-container .page-title .details-container #download-buttons .download-button--inner-button:hover {
  background-color: white;
  box-shadow: 0 0 10px 0 #4eb3d4;
}

@media screen and (max-width: 600px) {
  #reporting-container .page-title .details-container #download-buttons {
    width: auto;
  }
  #reporting-container .page-title .details-container #download-button {
    display: none;
  }
}

#reporting-container iframe {
  width: calc(100% - 10px) !important;
}

.ReportsFilter__FilterButtons_container {
  padding-top: 12px !important;
}

@media screen and (max-width: 1200px) {
  /* Empty media query preserved from original */
} 