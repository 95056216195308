.ls-card {
  font: 14px 'Gotham', sans-serif;
  line-height: normal;
  font-weight: 400;
  position: relative;
  display: inline-block;
  margin-bottom: 24px;
  cursor: default;
  min-width: 200px;
  background: #ffffff;
  box-shadow: 0 0 10px 0 #eaeaea;
  border-radius: 13px;
  overflow: hidden;
}

.ls-card.cutout-right {
  overflow: visible;
}

.ls-card.cutout-right:after {
  display: block;
  content: '';
  position: absolute;
  top: 55px;
  right: -10px;
  width: 52px;
  height: 60%;
  border-radius: 13px;
  background: #f4f7f9;
  z-index: 1;
}

.ls-card.cutout-left {
  overflow: visible;
}

.ls-card.cutout-left:after {
  display: block;
  content: '';
  position: absolute;
  top: 55px;
  left: -10px;
  width: 52px;
  height: 60%;
  border-radius: 13px;
  background: #f4f7f9;
  z-index: 1;
}

.ls-card .card-body {
  position: relative;
  padding: 20px 20px 15px;
}
