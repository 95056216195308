/* Reset styles */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
}

*:after,
*:before {
  box-sizing: border-box;
}

*:focus,
*:active,
*:hover,
*:visited {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
a,
img,
input,
button,
textarea {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: 0;
  display: block;
} 