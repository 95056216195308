#reports-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  min-width: 350px;
  padding-bottom: 30px;
}

#reports-frame .fa-long-arrow-up {
  color: #4ed4b2;
}

#reports-frame .fa-long-arrow-down {
  color: #eb6383;
}

#reports-frame .card-tooltip {
  z-index: 99999999999999;
}

#reports-frame .graph-container {
  display: inline-flex;
  min-height: 230px;
  flex-grow: 1;
}

#reports-frame .graph-container .ls-card {
  flex-grow: 1;
  width: 100%;
}

#reports-frame .graph-container .card-body {
  position: relative;
  padding: 20px 10px 15px;
}

#reports-frame .graph-container .card-body .cutout {
  top: 55px;
}

#reports-frame .graph-container .card-body .graph-placeholder {
  transition: all 0.8s;
  height: 280px;
  top: 42px;
  border-radius: 30px;
}

#reports-frame .graph-container .ls-card.cutout-right:after,
#reports-frame .graph-container .ls-card.cutout-left:after {
  top: 55px;
  z-index: auto;
}

@media screen and (max-width: 900px) {
  #reports-frame .graph-container .card-body .cutout {
    display: none;
  }
  #reports-frame .graph-container .ls-card.cutout-left:after {
    display: none;
  }
}

#reports-frame .graph-container .graph-detail-container {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

#reports-frame .graph-container .graph-detail-container .graph-title {
  font-size: 16px;
  font-weight: 600;
}

#reports-frame .graph-container .graph-detail-container i {
  font-size: 16px;
}

#reports-frame .graph-container .graph-data-container {
  width: calc(100% - 25px);
  height: 250px;
}

#reports-frame .security-score-container {
  display: inline-flex;
  width: 320px;
  flex-grow: 1;
  margin-right: 20px;
}

#reports-frame .security-score-legend {
  position: absolute;
  z-index: 1;
  top: 37px;
  left: -27px;
  right: 0;
  width: 93px;
  font-size: 13px;
  text-align: center;
  margin: 0 auto;
  white-space: nowrap;
}

#reports-frame .security-score-by-category-container {
  display: inline-flex;
  width: 530px;
  flex-grow: 2;
  margin-left: 5px;
}

#reports-frame .security-score-over-time-container {
  display: flex;
  max-width: 1680px;
  flex-grow: 1;
  margin: 0 auto;
}

#reports-frame .high-risk-container,
#reports-frame .bottom-rank-container {
  display: inline-flex;
  flex-grow: 1;
}

@media screen and (max-width: 680px) {
  #reports-frame .security-score-by-category-container {
    margin-left: 0;
  }
  #reports-frame .security-score-legend {
    left: -45px;
  }
}

#reports-frame .license-distribution-container {
  display: flex;
  flex-grow: 1;
}

#reports-frame .assigned-training-completion-container {
  display: flex;
  flex-grow: 1;
}

#reports-frame .assigned-training-completion-container .graph-data-container {
  height: 235px;
}

#reports-frame .assigned-training-completion-container .assigned-completion-rate-graph {
  font-size: 60px;
  font-weight: 600;
}

#reports-frame .participant-progress-container {
  display: flex;
  flex-grow: 1;
}

#reports-frame .participant-progress-over-time-container {
  display: flex;
  max-width: 1600px;
  flex-grow: 1;
  margin: 0 auto;
}

#reports-frame .low-progress-assigned-training-container {
  display: flex;
  max-width: 1600px;
  flex-grow: 1;
  margin: 0 auto;
}

#reports-frame .low-progress-assigned-training-container .timeline.wide {
  max-width: 800px;
}

[id^='chart-axis-1-tickLabels-'] > tspan {
  font-size: 11px !important;
}

[id^='chart-axis-2-tickLabels-'] > tspan {
  font-size: 10px !important;
}

#reports-frame > div.security-score-by-category-container > div > div.ls-card.cutout-left > div > div.graph-data-container > div > div > svg > g:nth-child(3) > line {
  display: none;
}

#reports-frame > div.security-score-by-category-container > div > div.ls-card.cutout-left > div > div.graph-data-container > div > div > svg > g:nth-child(2) > line {
  display: none;
}

@media screen and (max-width: 870px) {
  #reports-frame .graph-container .card-body .cutout {
    display: none;
  }
  #reports-frame .graph-container .ls-card.cutout-left:after {
    display: none;
  }
  #reports-frame .graph-container .ls-card.cutout-right:after {
    width: 100px;
  }
  #reports-frame .security-score-by-category-container {
    margin-left: 0;
  }
  #reports-frame .security-score-legend {
    left: -85px;
  }
} 