#puzzle-modal--container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#puzzle-modal {
  background: var(--white);
  min-width: 30rem;
  max-width: 35rem;
  padding: 2rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 2;
  font-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid var(--blue);
  box-shadow: 3px 4px 0 var(--blue);
}

#puzzle-modal button,
#puzzle-modal .puzzleinstructionsmodal-button:hover,
#puzzle-modal .puzzleinstructionsmodal-button:active,
#puzzle-modal .puzzleinstructionsmodal-button:focus {
  outline: none !important;
}

#puzzle-modal h2 {
  font-size: 1.5rem;
  color: var(--blue);
  margin: 1rem 0 0;
}

#puzzle-modal img {
  margin: 1rem 0 0;
  width: 450px;
}

.puzzleinstructionsmodal-caption {
  font-size: 1rem;
  font-style: italic;
  margin: 1rem 0;
}

#puzzle-modal .puzzleinstructionsmodal-title {
  font-weight: 700;
}

#puzzle-modal .puzzleinstructionsmodal-text {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 0 2rem;
  margin: 0;
}

#puzzle-modal .puzzleinstructionsmodal-text p {
  margin-bottom: 2rem;
}

#puzzle-modal .puzzleinstructionsmodal-copy--button {
  padding: 10px 40px;
  background: var(--blue);
  color: var(--white);
  border-color: transparent;
  border-radius: 60px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  cursor: pointer;
  outline: none !important;
  width: 8.75rem;
  padding: 0.625rem 0;
  font-weight: 500;
  margin: 1.25rem auto 0.3125rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#puzzle-modal .puzzleinstructionsmodal-copy--button:hover {
  opacity: 0.9;
}

#puzzle-modal .puzzleinstructionsmodal-copy--button:active {
  -webkit-box-shadow: inset 1px 1px 3px var(--grey1);
  -moz-box-shadow: inset 1px 1px 3px var(--grey1);
  box-shadow: inset 1px 1px 3px var(--grey1);
}

.puzzleinstructionsmodal-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
}

.puzzleinstructionsmodal-button {
  padding: 10px 40px;
  background: var(--blue);
  color: var(--white);
  border-color: transparent;
  border-radius: 60px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  cursor: pointer;
  outline: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.puzzleinstructionsmodal-button:hover {
  opacity: 0.9;
}

.puzzleinstructionsmodal-button--container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.puzzleinstructionsmodal-progress {
  transform: translateY(-4.5rem);
  background: #f4f4f4;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: -4rem;
}

.puzzleinstructionsmodal-progress > p {
  margin: 2rem 0 0;
} 